import * as React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Badge} from '@mui/material/';
import StarIcon from '@mui/icons-material/Star';
import Tooltip from '@mui/material/Tooltip';
import {decode, encode} from 'html-entities';
import ReactGA from 'react-ga4';
import gaEvent from "./handleEvent";

function ProductCard({product, updateDetail, openDetailedPanel, selectedView}) {
    let color = 'rgba(0, 150, 205, ' + (product.score / 1000).toFixed(1) + ')'

    switch (selectedView) {
        default:
        case 'grid':
            return (
                <Card raised={false}
                      onClick={() => {updateDetail(product.id); openDetailedPanel()}}
                      className="product-card"
                      sx={{
                          minWidth: '235px',
                          maxWidth: '400px',
                          boxShadow: '0 0 10px 10px #dddddd70',
                          borderRadius: '20px',
                          cursor: 'pointer'
                      }}>
                    <Badge
                        sx={{
                            right: '30px',
                            top: '30px',
                            display: 'block',
                            position: 'relative',
                            "& .MuiBadge-badge": {
                                height: '30px',
                                width: '30px',
                                borderRadius: '100%',
                                fontSize: '0.8rem',
                                backgroundColor: color,
                                color: 'white',
                            }
                        }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            margin: '2',
                            width: '250px',
                            height: '250px',
                            overflow: 'hidden',
                            objectFit: 'cover'
                        }}
                        badgeContent={(product.score / 100).toFixed(1)}>
                    </Badge>
                    <Box
                        sx={{display: 'flex', textAlign: 'left', p: 0, m: 2, borderRadius: 1, overflow: 'hidden'}}>
                        <Box
                            sx={{
                                color: 'black',
                                fontSize: 24,
                                fontWeight: '500',
                                alt: product?.product_data?.title,
                                textOverflow: 'ellipsis',
                            }}
                            className='product-header'
                        >
                            <div style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                <Box
                                    component="div"
                                    sx={{
                                        my: 0,
                                        mt: '-4px',
                                        p: 0,
                                    }}
                                >
                                    <Tooltip title={product?.product_data?.title}
                                        sx={{zIndex: 30000}}>
                                        {/*<Typography sx={{fontSize: '24px', fontWeight: '800', textOverflow: 'ellipsis', overflow: 'hidden',}}>{product?.product_data?.title}</Typography>*/}
                                        <Typography sx={{fontSize: '24px', fontWeight: '800', textOverflow: 'ellipsis', overflow: 'hidden',}}>{decode(product?.product_data?.title)}</Typography>
                                    </Tooltip>
                                </Box>
                            </div>
                            <div style={{height: 20, whiteSpace: 'wrap'}}>
                                <Box
                                    component="div"
                                    sx={{
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        display: '-webkit-box',
                                        p: 0,
                                        mt: '-4px',
                                        color: '#ADADAD',
                                        fontSize: 14
                                    }}
                                    style={{
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: '1',
                                    }}
                                >
                                    <Typography>{product?.product_data?.description}</Typography>
                                </Box>
                            </div>
                        </Box>
                    </Box>
                    <img style={{
                        width: '100%',
                        aspectRatio: '1/1',
                        left: '-20px',
                        objectFit: 'cover'
                    }}
                         src={product?.images[0]}>
                    </img>

                    <Box
                        sx={{display: 'flex', justifyContent: 'space-between', p: 0, m: 2, borderRadius: 1}}>
                        <Typography sx={{
                            color: 'black',
                            fontSize: 18,
                            fontFamily: 'SF Pro Display',
                            fontWeight: '800',
                            wordWrap: 'break-word'
                        }}
                        >
                            ${product.product_data.discounted_price ? product.product_data.discounted_price : product.product_data.price}
                        </Typography>
                        <Box sx={{display: 'flex'}}>
                            <StarIcon sx={{fontSize: 18, color: `${product?.product_data?.rating ? 'orange' : '#ADADAD'}`, mr: 1, mt: '2px'}}/>
                            <Typography sx={{
                                color: 'black',
                                fontSize: 14,
                                fontWeight: '500',
                                lineHeight: '1.7'
                            }}>{product?.product_data?.rating}</Typography>
                        </Box>
                        <Typography sx={{ lineHeight: '1.2' }}>
                            {product?.product_data?.source === 'walmart' ? 'Walmart' : product?.product_data?.source}
                        </Typography>
                    </Box>
                </Card>
            );
        case 'lines':
            return (
                <Card
                    raised={false}
                    onClick={() => {
                        updateDetail(product.id);
                        openDetailedPanel();
                        gaEvent('User', 'Product Details', 'Product Details Clicked')
                    }}
                    className="product-card"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        maxWidth: '800px',
                        boxShadow: '0 0 10px 10px #dddddd70',
                        borderRadius: '20px',
                        padding: '20px',
                        margin: '30px 0',
                        cursor: 'pointer',
                        '&:first-of-type': {
                            marginTop: 0,
                        },
                        '&:last-of-type': {
                            marginBottom: 0,
                        },
                    }}
                >
                    <img
                        style={{
                            width: '200px',
                            height: '200px',
                            objectFit: 'cover',
                            borderRadius: '10px',
                            marginRight: '20px',
                            aspectRatio: '1 / 1',
                        }}
                        src={product?.images[0]}
                        alt={product?.product_data?.title}
                    />

                    <Box sx={{ flex: 1, textAlign: 'left', width: 'calc(100% - 240px)' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '24px',
                                    fontWeight: '800',
                                    WebkitLineClamp: '3',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    whiteSpace: 'normal',
                                    marginRight: '30px',
                                    minHeight: '90px',
                                    lineHeight: '1.25',
                                }}
                            >
                                {decode(product?.product_data?.title)}
                            </Typography>
                            <Badge
                                sx={{
                                    "& .MuiBadge-badge": {
                                        height: '30px',
                                        width: '30px',
                                        borderRadius: '100%',
                                        fontSize: '0.8rem',
                                        backgroundColor: color,
                                        color: 'white',
                                        position: 'relative',
                                        top: '-20px',
                                        right: '10px'
                                    },
                                }}
                                badgeContent={(product.score / 100).toFixed(1)}
                            />
                        </Box>

                        <Typography
                            sx={{
                                color: '#ADADAD',
                                fontSize: '14px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                WebkitLineClamp: '3',
                                margin: '10px 0',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                whiteSpace: 'normal',
                                minHeight: '60px'
                            }}
                        >
                            {product?.product_data?.description}
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: 'black',
                                    fontSize: '18px',
                                    fontWeight: '800',
                                }}
                            >
                                ${product.product_data.discounted_price || product.product_data.price}
                            </Typography>

                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <StarIcon
                                    sx={{
                                        fontSize: '18px',
                                        color: product?.product_data?.rating ? 'orange' : '#ADADAD',
                                        marginRight: '4px',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        color: 'black',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                    }}
                                >
                                    {product?.product_data?.rating}
                                </Typography>
                            </Box>

                            <Typography sx={{ lineHeight: '1.2', fontSize: '14px' }}>
                                {product?.product_data?.source === 'walmart' ? 'Walmart' : product?.product_data?.source}
                            </Typography>
                        </Box>
                    </Box>
                </Card>
            );
    }


}

export {ProductCard}