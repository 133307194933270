import React, { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {Container, Grid, Card, Button } from "@mui/material/";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { CiGrid41 } from "react-icons/ci";
import { CiGrid2H } from "react-icons/ci";
import useClickOutside from '../pages/useClickOutside';
import { useSelector, useDispatch } from "react-redux";
import {
    setChatSessionId,
    setHistory,
    selectHistory,
    selectDiscourse,
    selectSearching,
    selectProducts,
    setSearching,
    setProducts,
    clearData,
} from "../redux/dataSlice";
import {
    recordAnonSearch,
} from "../redux/identitySlice";
import { ProductCard } from "../components/ProductCard";
import { DetailView } from "../components/DetailView";
import { DetailViewMob } from "../components/DetailViewMob";
import { ChatDialog } from "../components/ChatDialog";
import { TextEntry } from "../components/TextEntry";
import { DebugVertical } from "../components/DebugVertical";
import { HistorySelector } from "../components/HistorySelector";
import logo from "../assets/buyerry-icon.svg";
import { LiaCrosshairsSolid } from "react-icons/lia";
import { LiaDollarSignSolid } from "react-icons/lia";
import { LiaSortAmountDownSolid } from "react-icons/lia";
import { LiaStar } from "react-icons/lia";
import { LiaSortAlphaDownSolid } from "react-icons/lia";
import {CornerAvatar} from "../components/CornerAvatar";
import gaEvent from "../components/handleEvent";

const { RestFunctions } = require("../repositories/Network");


function MainPage({ chatSessionId, conversationHandler, checkSignupRequired, userSignOut, isMobile, isTyping, setIsTyping }) {
    const [showDebug, setShowDebug] = useState();
    const [showHistory, setShowHistory] = useState();

    const dispatch = useDispatch();
    const discourse = useSelector(selectDiscourse);
    const searching = useSelector(selectSearching);
    const products = useSelector(selectProducts);
    const history = useSelector(selectHistory);
    const [query, setQuery] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const [searchStatus, setSearchStatus] = useState(null);
    const [detailItem, setDetailItem] = useState();
    const [rightPanelVisible, setRightPanelVisible] = useState(!isMobile);
    const [leftPanelVisible, setLeftPanelVisible] = useState(!isMobile);
    const [detailedPanelVisible, setDetailedPanelVisible] = useState(false);
    const [smessageId, setSmessageId] = useState(null);

    const chatRef = useRef();
    const panelRef= useRef(null);

    const resetSession = () => {
        console.log("MainPage: resetSession()");

        if (!checkSignupRequired()) {
            dispatch(clearData());
            dispatch(setChatSessionId(null));
            setSmessageId(null);
            setQuery("");
            setErrorMessage("");
            setSearchStatus(null);
            setDetailItem();
        }
    };

    // ================== get token =================

    const updateDetailItem = (id) => {
        const i = products.find((e) => e.id === id);
        console.log("updateDetailProduct:", i);
        if (i) setDetailItem(i);
    };


    const toggleRightPanel = () => {
        setRightPanelVisible(!rightPanelVisible);
        console.log("right panel visible: ", rightPanelVisible);
    };

    const closeRightPanel = (e) => {
        if (isMobile) {
          setRightPanelVisible(false);
        } else {
          e.preventDefault();
          setRightPanelVisible(true);
        }
    };

    const toggleLeftPanel = () => {
        setLeftPanelVisible(!leftPanelVisible);
    };

    const openDetailedPanel = () => {
        gaEvent('User', 'Product Details', 'Product Details Clicked');
        setDetailedPanelVisible(true);
    };


    const closeDetailedPanel = () => {
        setDetailedPanelVisible(false);
    };

    const viewStyles = [
        {id: 'grid', icon: <CiGrid2H style={{ fontSize: 30, marginRight: '8px', color: '#888', cursor: 'pointer', fontWeight: 800 }}/>},
        {id: 'lines', icon: <CiGrid41 style={{ fontSize: 30, marginRight: '8px', color: '#888', cursor: 'pointer', fontWeight: 800 }}/>}
    ]

    const options = [
        { id: 'match', label: 'Best Match', icon: <LiaCrosshairsSolid />},
        { id: 'price', label: 'Best Price', icon: <LiaDollarSignSolid />},
        { id: 'rating', label: 'Best Rating', icon: <LiaStar />},
        { id: 'abc', label: 'A to Z', icon: <LiaSortAlphaDownSolid />},
    ];

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedView, setSelectedView] = useState(viewStyles[0].id);

      const handleSort = (a, b) => {
        switch (selectedOption) {
          case 'price':
            return a.product_data.price - b.product_data.price;
          case 'rating':
            return b.product_data.rating - a.product_data.rating;
          case 'abc':
            const nameA = a.product_data.title || '';
            const nameB = b.product_data.title || '';
            return nameA.localeCompare(nameB);
          case 'match':
          default:
            return b.score - a.score;
        }
      };

    const closeDropdown = () => {
       setIsOpen(false);
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleView = () => {
        const currentIndex = viewStyles.findIndex(view => view.id === selectedView);
        const nextIndex = (currentIndex + 1) % viewStyles.length;
        setSelectedView(viewStyles[nextIndex].id);
    };

    const handleOptionClick = (optionId) => {
        setSelectedOption(optionId);
        setIsOpen(false); // Close dropdown after selection
    };

    useClickOutside(panelRef, closeDropdown);


    useEffect(() => {
        if (!products || products.length === 0) {
            setDetailItem();
            return
        }
        console.log("update detail item:", products[0].id);
        updateDetailItem(products[0].id);
        resetScroll();
    }, [products]);

    useEffect((e) => {
        const handleResize = (e) => {
            setRightPanelVisible(true)
            if (isMobile) {
                // setRightPanelVisible(false);
            } else {
                setRightPanelVisible(true);
                if (!rightPanelVisible) {
                    setRightPanelVisible(true)
                }
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);


    useEffect(() => {
        if (!discourse) return;
        resetScroll();
        if (isMobile) setLeftPanelVisible(false);
        }, [discourse, detailItem]);

    const startSearch = async () => {
        setIsTyping(false);
        gaEvent('User', 'Search Start', 'Search Started');
        if (checkSignupRequired()) {
            return;
        }

        if (isMobile){ setRightPanelVisible(searching) }

        dispatch(setSearching(true));
        const response = await RestFunctions.getHistory(chatSessionId);
        if (response) {
            const buyerryMessages = response.messages.filter(e => e.message_type === 'external');
            const message_id = buyerryMessages.length > 0 ? buyerryMessages[buyerryMessages.length - 1].mesage_id : null;
            setSmessageId(message_id);
            dispatch(recordAnonSearch(message_id));
            findProducts(message_id);
        } else console.log("ERROR: no response from request history");
    };

    const findProducts = async (message_id) => {
        startSearchStatus(message_id);
        const response = await RestFunctions.search(message_id);
        dispatch(setSearching(false));

        if (response) {
            getProducts(message_id);
            gaEvent('User', 'Search Success', 'Search is Successful');
            RestFunctions.gcpData({ target: "user_data" }).then((e) => {
                console.log("user data", e);
                dispatch(setHistory(e.data));
            });
        } else {
            setErrorMessage("Server returned error message during search...");
        }
    };

    const publishProducts = (p) => {
        const rezults = p?.products?.map((e) => {
            return {
                images: e.medias,
                product_data: e.product_data,
                reviews: e.reviews,
                score: e.score,
            };
        });
        if (rezults) dispatch(setProducts(rezults));
    };

    const getProducts = async (messageId) => {
        const response = await RestFunctions.getProducts(messageId ? messageId : smessageId)
        publishProducts(response)
    };

    const _timer = { start: null };

    const getTimerId = () => {
        return _timer;
    };

    const startTimer = (i) => {
        const d = new Date();
        _timer.start = d.getTime();
        _timer.interval = i;
        console.log("start timer", _timer);
    };

    const resetTimer = () => {
        console.log("resetTimer", _timer);
        clearInterval(_timer.interval);
        _timer.start = null;
        _timer.interval = null;
    };

    const getProductSearchStatus = async (messageId) => {
        const response = await RestFunctions.gcpData({
            target: "search_products",
            message_id: messageId ? messageId : 1066,
        });
        console.log("================RESPONSE!!!!", response.data.products);
        if (response.data.products.length > 0) {
            console.log("response", response.data);
            publishProducts(response.data);
            if (isMobile) {
                setRightPanelVisible(true);
            }
        }

        if (response.data.search_status === "COMPLETED") {
            resetTimer();
            return;
        }

        console.log(
            "==========timer========",
            new Date().getTime() - getTimerId()?.start
        );
        if (new Date().getTime() - getTimerId()?.start > 60000) {
            resetTimer();
        }
    };

    const startSearchStatus = (messageId) => {
        //startTimer()
        setTimeout(() => {
            startTimer(
                setInterval(() => {
                    // runs every 2 seconds
                    getProductSearchStatus(messageId);
                }, 2000)
            );
        }, 2000);
    };

    const queryUpdate = (v) => {
        setQuery(v);
    };

    const handleSubmit = (e) => {
        gaEvent('User', 'Message Sent', 'Message Sent');
        e.preventDefault();
        if (query) {
            setIsTyping(true);
        } else {
            setIsTyping(false);
        }
        conversationHandler(query, setIsTyping);
        setQuery("");
        resetScroll();
    };

    const [pos, setPos] = useState(false);

    const resetScroll = () => {
        try {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        } catch (ex) { }
    };

    const handleScroll = (e) => {
        if (chatRef.current.scrollTop > 50) {
            if (!pos) setPos(true);
        } else {
            if (pos) setPos(false);
        }
    };

    useEffect(() => {
        const urlSearchString = window.location.search;
        const params = new URLSearchParams(urlSearchString);
        const temp = chatRef.current;

        setShowDebug(params.get("debug"));
        setShowHistory(params.get("history"));
        temp.addEventListener("scroll", handleScroll);
        return () => temp.removeEventListener("scroll", handleScroll);
    });


    return (
            <>
                <Container className="app" sx={{display: 'flex', flexDirection: 'row', overflow: 'hidden'}}>
                    {/* Left Panel */}
                    <CornerAvatar userSignOut = {userSignOut}/>
                    <Grid className={`left-panel ${!leftPanelVisible ? 'collapsed' : ''}`} sx={{boxShadow: '0 0 20px 10px #dddddd', zIndex:'200000'}}>
                        <Grid sx={{display: 'block', flexDirection: 'column', width: '100%'}}>
                            <Box sx={{ display: "block", height: "140px" }}>
                                <Card
                                    style={{
                                        width: '100%',
                                        cursor: 'pointer',
                                        boxShadow: 'none',
                                        backgroundColor: 'unset',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                    onClick={() => {
                                        gaEvent('User', 'New Request', 'New Request Button Clicked')
                                        resetSession();
                                    }}
                                >
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: 'column',
                                    padding: '0 14px',
                                }}>
                                <img
                                    src={logo}
                                    className="App-logo-min"
                                    alt="logo"
                                    style={{height: "36px", marginTop: '32px'}}
                                    sx={{mb: '30px', height: "30px", pointerEvents: "none"}}
                                />
                                        { leftPanelVisible && (

                                        <Button className='newRequest' sx={{
                                            mt: 4,
                                            borderRadius: 100,
                                            minWidth: 0,
                                            boxShadow: 'none',
                                            backgroundColor: '#0096cd',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            fontWeight: '800',
                                            '&.MuiButton-root:hover': {
                                                bgcolor: '#048abb',
                                                boxShadow: 'none',
                                            }
                                        }} variant="contained" aria-label="search">
                                            New Request
                                        </Button>
                                        )}

                                        { !leftPanelVisible && (
                                        <>
                                        <Button className='newRequest' sx={{
                                            mt: 4,
                                            borderRadius: 100,
                                            minWidth: 0,
                                            boxShadow: 'none',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            fontSize: '16px',
                                            fontWeight: '800',
                                            backgroundColor: '#0096cd',
                                            '&.MuiButton-root:hover': {
                                                bgcolor: '#048abb',
                                                boxShadow: 'none',
                                            }
                                        }} variant="contained" aria-label="search" endIcon={<AddCircleOutlinedIcon className='newRequestIcon'/>}>

                                        </Button>
                                        </>
                                        )}
                                    </Box>
                                </Card>
                                <br/>
                            </Box>
                            <Box sx={{height: "calc(100vh - 100px)", overflow: "auto", }}>
                                <HistorySelector history={history} leftPanelVisible={leftPanelVisible}/>
                            </Box>
                            <Box sx={{height: "calc(100vh - 300px)", width: '400px'}}>
                            {showDebug === "true" && (
                                <DebugVertical
                                    searchStatus={searchStatus}
                                    messageId={smessageId}
                                    detailItem={detailItem}
                                    publishProducts={publishProducts}
                                    resetScroll={resetScroll}
                                />
                            )}
                        </Box>
                        </Grid>

                        <button className={'lpbutton'} onClick={()=> {
                            toggleLeftPanel();
                            !leftPanelVisible && gaEvent('User', 'Left Panel Contracted', 'Left Panel Contracted');
                             leftPanelVisible && gaEvent('User', 'Left Panel Expanded',   'Left Panel Expanded');
                        }}>
                            {leftPanelVisible && (
                                <ArrowBackIosNewOutlinedIcon sx={{
                                    cursor: "pointer",
                                    opacity: '0.2'
                                }}/>
                            )}
                            {!leftPanelVisible && (
                                <ArrowForwardIosOutlinedIcon sx={{
                                    cursor: "pointer",
                                    opacity: '0.2'
                                }}/>
                            )}
                        </button>
                    </Grid>

                    {/* Middle Panel */}
                    <Grid className="main-content-new"
                          sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              borderRadius: '20px 0 0 20px',
                              mb: 4,
                              flex: '1',
                              overflow: 'hidden',
                          }}>

                        {/* Details Panel */}
                        <Grid className={`detailed-panel ${detailedPanelVisible ? 'visible' : ''}`}>
                            <IconButton
                                onClick={closeDetailedPanel}
                                type="button"
                                sx={{
                                    p: '10px',
                                    pt: 0,
                                    width: '60px',
                                    height: '32px',
                                    borderRadius: '0 0 20px 0',
                                    cursor: 'pointer'
                                }}
                                className='close-button'
                                aria-label="send">
                                <CloseOutlinedIcon/>
                            </IconButton>
                            {detailItem && (
                                <Box
                                    sx={{
                                        mt: 0,
                                        ml: 1,
                                        mb: 1,
                                        minHeight: "100%",
                                        height: "100%",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Grid container sx={{height: "80px", pl: "0"}}>
                                        <Grid item xs={12}
                                              className='detailed-panel-header'
                                              sx={{
                                            fontSize: '18px',
                                            fontWeight: '800',
                                            textAlign: 'left',
                                            color: '#adadad',
                                            pt: 5,
                                        }}>
                                            Item Details
                                        </Grid>
                                    </Grid>
                                    <DetailViewMob product={detailItem} onClose={closeDetailedPanel}/>
                                </Box>
                            )}
                        </Grid>

                        {/* Top Panel */}
                        <Grid className="top-panel" sx={{borderRadius: '20px 0 0 20px', display: 'none'}}>
                            {/* === START DETAIL VIEW === */}
                            {detailItem && (
                                <Box
                                    sx={{
                                        mt: 0,
                                        ml: 1,
                                        mb: 1,
                                        minHeight: "520px",
                                        height: "520px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Grid container sx={{height: "60px", pl: "0"}}>
                                        <Grid item xs={12} sx={{
                                            fontSize: '18px',
                                            textAlign: 'left',
                                            color: '#adadad',
                                            pt: 4,
                                            pl: 4
                                        }}>
                                            Detailed View
                                        </Grid>
                                    </Grid>

                                    {detailedPanelVisible &&
                                        <DetailView product={detailItem} onClose={closeDetailedPanel}/>}
                                    {/*<DetailView product={detailItem}/>*/}

                                </Box>
                            )}
                        </Grid>

                        <div className={`overlay ${detailedPanelVisible ? 'visible' : ''}`}
                             onClick={closeDetailedPanel}></div>
                        {/* Chat Panel */}

                        <Grid className="bottom-panel-container"
                              sx={{
                                  overflow: 'hidden',
                                  borderRadius: '20px 20px 0px 0px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  backgroundColor: 'white',
                              }}
                            // ref={ref}
                        >
                            {/* Chat Header */}
                            <Grid sx={{
                                position: 'relative',
                                zIndex: '10000',
                                padding: "0"
                            }}>
                                <Grid
                                    className='chatHeader'
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: '800',
                                        textAlign: 'left',
                                        color: '#adadad',
                                        pl: 4,
                                        pb: 4,
                                        mt: 0,
                                        padding: "25px"
                                    }}
                                >
                                    Chat
                                </Grid>
                            </Grid>

                            {/* Chat Body */}
                            <Grid className="chatBody"
                                  sx={{
                                      overflow: 'scroll',
                                      borderRadius: '20px 20px 0px 0px',
                                      ml: 0,
                                      height: '100%',
                                      maxHeight: '100%'
                                  }}
                                  ref={chatRef}
                            >
                                <Grid container sx={{pl: "0", display: 'flex', flexDirection: 'column', mt: 0}}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            height: "maxContent",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                flex: 1,
                                                backgroundColor: 'white',
                                                overflow: "scroll",
                                            }}
                                        >
                                            <Paper
                                                sx={{
                                                    alignContent: "flex-start !important",
                                                    boxShadow: 0,
                                                    backgroundColor: 'transparent',
                                                    paddingBottom: '20px'
                                                }}
                                            >
                                                <div className='chat' style={{marginTop: "auto", borderRadius: '20px', height: '100%'}}/>
                                                {discourse.map((item, index) => (
                                                    <ChatDialog
                                                        key={index}
                                                        id={index}
                                                        chat={item}
                                                        index={index}
                                                        startSearchHandler={startSearch}
                                                        searching={searching}
                                                        showSearch={discourse?.length > 0 && !searching && !errorMessage}
                                                    />
                                                ))}
                                            </Paper>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>

                            {/* Chat Input */}
                            <Grid className='textEntry' sx={{display: 'flex', flexDirection: 'row'}}>
                                <TextEntry
                                    sx={{m: 3}}
                                    style={{padding: '0', width: '100%'}}
                                    showSearch={discourse?.length > 1 && !searching && !errorMessage}
                                    discourse={discourse}
                                    query={query}
                                    queryUpdateHandler={queryUpdate}
                                    querySubmitHandler={handleSubmit}
                                    resetSession={resetSession} // this one is deprecated
                                    startSearchHandler={startSearch}
                                    toggleRightPanel={toggleRightPanel}
                                    searching={searching}
                                    closeRightPanel={closeRightPanel}
                                    isTyping={isTyping}
                                />
                            </Grid>
                        </Grid>

                    </Grid>

                    {/* Right Panel */}
                    <Grid className={`right-panel ${rightPanelVisible ? 'visible' : ''}`}
                          sx={{
                              mb: 4,
                              mt: '22px',
                              borderRadius: '0 20px 20px 0',
                              display: 'flex',
                              flexDirection: 'column',
                              flex: '2'
                          }}>
                        <Grid container sx={{pl: "0", display: 'flex'}} className='products-found-header'>
                            <Grid item
                                xs={12}
                                className = 'rpHeader'
                                sx={{
                                    fontSize: '18px',
                                    textAlign: 'left',
                                    fontWeight: '800',
                                    color: '#adadad',
                                    pt: 4,
                                    pl: 4,
                                    pb: 4,
                                    height: '72px',
                                    display: 'flex'
                                }}
                            >
                                Products Found: {products?.length}
                                <Box sx={{
                                    display: 'flex',
                                    p: '4px',
                                    mt: -1
                                }}>
                                    {!isMobile &&
                                        <div className="custom-dropdown">
                                            <div className="dropdown-header" onClick={toggleView}>
                                                { viewStyles.find(view => view.id === selectedView)?.icon }
                                            </div>
                                        </div>
                                    }
                                    <div className="custom-dropdown" ref={panelRef}>
                                        <div className="dropdown-header" onClick={toggleDropdown}>
                                            {selectedOption ? (
                                                <span>{options.find(option => option.id === selectedOption)?.icon}</span>
                                            ) : (
                                                <span><LiaSortAmountDownSolid/></span>
                                            )}
                                        </div>
                                        {isOpen && (
                                            <ul className="dropdown-list">
                                                {options.map(option => (
                                                    <li key={option.id} onClick={() => handleOptionClick(option.id)}>
                                                        <i className="dropdown-list-icon">{option.icon}</i> {option.label}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                overflow: "auto",
                                borderRadius: '20px',
                            }}
                        >
                            <Grid sx={{p: 5, pr: 4, pl: 4, display: `${selectedView === 'grid' ? 'grid' : 'block'}`,
                                gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
                                gap: 5,
                            }}
                                  className='products-wrap'
                            >
                                {products && products.length > 0 ? (
                                    products
                                        .slice().sort(handleSort).map((item, index) => (
                                            <ProductCard
                                                key={index}
                                                product={item}
                                                updateDetail={updateDetailItem}
                                                openDetailedPanel={openDetailedPanel}
                                                selectedView={selectedView}
                                            />
                                            )
                                        )
                                    ) : (<p></p>)
                                }
                                    {isMobile && <Box className='spacer50'></Box>}
                              </Grid>
                        </Box>
                    </Grid>
                </Container>
            </>

    );
}

export { MainPage };
