import { signInWithPopup, signOut } from "firebase/auth";
import { auth, googleProvider } from "../firebase";
import gaEvent from "../components/handleEvent";

const googleSignup = async () => {
    try {
      const z = await signInWithPopup(auth, googleProvider);
      gaEvent('User', 'User Signed Up', 'User Signed Up');
    } catch (error) {
      console.log(error);
    }
  };

const gSignup = async () => {
    await googleSignup()
}

const gSignout = () => {
  signOut(auth)
    .then(() => {
      gaEvent('User', 'User Signed Out', 'User Signed Out');
    })
    .catch((error) => console.log(error));
};

export const AuthFunctions = {
    googleSignInWithPopup: gSignup,
    googleSignOut: gSignout,
}