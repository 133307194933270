import { createSlice } from '@reduxjs/toolkit';  

const ANON_SEARCHES = 'anon-searches'

const getAnonSearchesFromStorage = () => {
  let anonSearches = localStorage.getItem(ANON_SEARCHES)
  if (anonSearches) {
    anonSearches = JSON.parse(anonSearches)
  } else {
    anonSearches = []
  }
  return anonSearches
}

export const identitySlice = createSlice({
  name: 'identity',
  initialState: {
    user: null,
    anonSearches: getAnonSearchesFromStorage(),
  },
  reducers: {
    recordAnonSearch: (state, action) => {
      
      if (state.user) { return }
      let newSearches = JSON.parse(JSON.stringify(state.anonSearches));
      if (!newSearches) newSearches = []
      if (!Array.isArray(newSearches)) {
        newSearches = [];
      }
      newSearches.push(action.payload)
      localStorage.setItem(ANON_SEARCHES, JSON.stringify(newSearches))

      return {
          ...state,
          anonSearches: newSearches
      }
    },

    logoutIdentity: (state, action) => {
        return {
            ...state,
            user: null
        }

    },
    setUser: (state, action) => {
        const payload = action.payload
        const id = {
            displayName: payload.displayName,
            email: payload.email,
            accessToken: payload.accessToken,
            photoURL:  payload.photoURL,
            uid:  payload.uid,
            phoneNumber:  payload.phoneNumber,
            providerId:  payload.providerId,
        }

        return {
            ...state,
            user: id,
        }
    },
  }
});

export const { setUser, logoutIdentity, recordAnonSearch } = identitySlice.actions;
export const selectUser = (state) => state.identity.user;
export const selectAnonSearches = (state) => state.identity.anonSearches;

export default identitySlice.reducer;