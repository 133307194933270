import React, { useState, useEffect, useRef, useCallback } from 'react';

import { SignIn } from './auth/SignIn'

import wordLogo from '../assets/buyerry.svg';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material/';

function SignInDialog({message, openDialog, handleCloseDialog}) {
    const [isSignup, setIsSignup] = useState(false);

    return (
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            className="sign-in-bg"
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());
                    const email = formJson.email;
                    console.log(email);
                    handleCloseDialog();
                },
            }}>
            <DialogTitle>
                <Box sx = {{ position: 'relative', height: '150px' }}>
                    <Box sx = {{ width: '100%', textAlign: 'center', position: 'absolute', top: '80%'}}>
                        <img src={wordLogo} className="App-logo" alt="wordLogo" style={{height: '56px'}}  />
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent sx = {{ textAlign: 'center' }}>
                <DialogContentText sx = {{ padding: 6, fontSize: 16 }}>
                    {message}
                    <SignIn />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

export {SignInDialog}