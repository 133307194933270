export const getDiscourseData = () => { 
    return [
        {
            "actor": "user",
            "message": "i would like to buy a new lamp",
            "timestamp": "2024-04-03T18:50:14.556Z"
        },
        {
            "actor": "ai",
            "message": "1. What type of lamp are you looking for? (e.g. desk lamp, floor lamp, table lamp)\n2. Do you have a specific color or design preference for the lamp?\n3. Are you looking for a lamp with any specific features, such as adjustable brightness or a built-in USB port?",
            "timestamp": "2024-04-03T18:50:16.812Z"
        },
        {
            "actor": "user",
            "message": "desk lamp",
            "timestamp": "2024-04-03T18:50:21.846Z"
        },
        {
            "actor": "ai",
            "message": "1. What is the primary use for the desk lamp? (e.g. reading, studying, working)\n2. Do you have a preferred light bulb type for the desk lamp? (e.g. LED, incandescent)\n3. Are you looking for a desk lamp with any specific additional features, such as a flexible neck or a built-in organizer?",
            "timestamp": "2024-04-03T18:50:24.150Z"
        },
        {
            "actor": "user",
            "message": "vintage green",
            "timestamp": "2024-04-03T18:50:32.571Z"
        },
        {
            "actor": "ai",
            "message": "1. What size desk are you looking to place the lamp on?\n2. Do you have a specific budget in mind for the desk lamp?\n3. Would you prefer a desk lamp with a switch on the base or on the cord?",
            "timestamp": "2024-04-03T18:50:34.378Z"
        }
    ]
}
    