import * as React from 'react';
import ReactGA from 'react-ga4';
import {InputBase, Paper, Divider, Box, IconButton, LinearProgress} from '@mui/material/'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SavedSearchOutlinedIcon from '@mui/icons-material/SavedSearchOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function TextEntry({showSearch, discourse, query, queryUpdateHandler, querySubmitHandler, resetSession, startSearchHandler, toggleRightPanel, searching, closeRightPanel, isTyping}) {
    const inputRef = React.useRef(null);

    const handleSearchClick = () => {
        if (inputRef.current) {
            queryUpdateHandler(inputRef.current.value);
            alert(inputRef.current.value)
        }
        alert(inputRef.current)
    };

    return (
        <Paper className='textEntry' elevation={0} sx={{
            textAlign: 'left',
            ml: 0,
            mb: 0,
            mt: 0,
            mr: 0,
            boxShadow: '0 8px 20px 0px #dddddd',
            borderRadius: '0 0 20px 20px',
            width: '100%'
        }} component="form" onSubmit={querySubmitHandler}>
            <Divider variant='middle' sx={{
                margin: 0,
            }}/>
            <MoreHorizIcon
                style={{
                    backgroundColor: '#eeeeee',
                    borderRadius: '10px',
                    width: '40px',
                    color: 'white',
                }}
                className={`thinking ${isTyping ? 'active' : ''}`}
            ></MoreHorizIcon>
            {searching &&
                <div>
                <LinearProgress sx={{
                        width: '100%',
                        margin: 0,
                        ml: 0,
                        height: '2px',
                        opacity: 0.8,
                        marginTop: '-1px'
                    }} color="primary"/>
                </div>
            }
            <Box sx={{display: 'flex'}}>
                <InputBase
                    sx={{ml: 0, pl: 3, mb: 0, flex: 1, width: '100%', height: '60px'}}
                    placeholder={!searching ? 'Find you what you need' : 'Searching...'}
                    inputProps={{'aria-label': 'search for what you need'}}
                    value={query}
                    onChange={(e) => queryUpdateHandler(e.target.value)}
                    onClick={closeRightPanel}
                    className='InputField'
                />

                {searching &&
                    <Box sx={{width: '100%'}}>
                    </Box>}
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    {showSearch &&
                        <IconButton
                            onClick={toggleRightPanel}
                            className='SavedSearch'
                            type="button"
                            sx={{
                                p: '10px',
                                width: '60px',
                                height: '60px',
                                borderRadius: '0'
                            }}
                            aria-label="send">
                            <SavedSearchOutlinedIcon/>
                        </IconButton>
                    }
                    <IconButton
                        onClick={querySubmitHandler}
                        type="button"
                        sx={{
                            p: '10px',
                            width: '60px',
                            height: '60px',
                            borderRadius: '0 0 20px 0'
                        }}
                        aria-label="send">
                        <ArrowForwardIosIcon/>
                    </IconButton>
                </Box>
            </Box>
        </Paper>
    )
}

export {TextEntry}