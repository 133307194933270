import { configureStore } from '@reduxjs/toolkit';
import dataReducer from './dataSlice';
import identityReducer from './identitySlice';

export default configureStore({

  reducer: {
    identity: identityReducer,
    data: dataReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
});