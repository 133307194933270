// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC6HCYr3ObGjF5ZfcsErfPFmaXIVUyiabc",
  authDomain: "apt-retina-400218.firebaseapp.com",
  projectId: "apt-retina-400218",
  storageBucket: "apt-retina-400218.appspot.com",
  messagingSenderId: "639017377232",
  appId: "1:639017377232:web:f0bbc2522360cd8c7cedd5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);



// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();