

export const getProductData = () => { 
    return {
            "products": [
            {
                "medias": [
                    "https://target.scene7.com/is/image/Target/GUEST_5af426be-76d4-4303-94cb-6be37a96db0e",
                    "https://target.scene7.com/is/image/Target/GUEST_5c171bb4-34bf-4e9d-9a5f-62370f6eb94d",
                    "https://target.scene7.com/is/image/Target/GUEST_71cdeb8a-a670-4ea8-9fa2-038233b6b0fd",
                    "https://target.scene7.com/is/image/Target/GUEST_1c42032a-ff4d-4912-818c-d8fd4ed801b5",
                    "https://target.scene7.com/is/image/Target/GUEST_0f70393d-2489-40d8-b86f-1e3a56248937",
                    "https://target.scene7.com/is/image/Target/GUEST_4f1ab8d2-56dd-49be-928e-3a2e22e0b904",
                    "https://target.scene7.com/is/image/Target/GUEST_49f15196-d46c-4b1e-b90a-670908abaae0"
                ],
                "product_data": {
                    "brand": "Nintendo",
                    "category": null,
                    "color_hex": null,
                    "color_name": null,
                    "description": "TWO MODES IN ONE - Enjoy all the excitement and action of the original \u201cPAC-MAN CHAMPIONSHIP EDITION 2\u201d with an all new 2-player co-op mode available exclusively for the Nintendo Switch; GHOST TRAIN BOOST - Evade four unique types of Ghost Trains that power up through the game based on their ghostly characteristics; EVOLVED PAC - Gameplay evolves into 3D yet maintains the classic maze chase arcade action from the original along with new features including Time Attack, Adventure Mode with fast fleeting fruit, big bad bosses, ridiculous remixed rules and more!; CO-OP MADNESS - Enjoy the speedy co-op game with a partner by eating all the Pac Dots and completing the maze together.",
                    "discounted_price": null,
                    "id": 86,
                    "price": 19.99,
                    "product_currency": "usd",
                    "rating": 3.0,
                    "short_description": null,
                    "size": null,
                    "source": "circle",
                    "source_url": "https://www.target.com/p/pac-man-championship-edition-2-plus-nintendo-switch-digital/-/A-79490887",
                    "title": "Pac-Man: Championship Edition 2 Plus - Nintendo Switch (Digital)"
                },
                "reviews": [],
                "score": 8.0
            },
            {
                "medias": [
                    "https://target.scene7.com/is/image/Target/GUEST_e6bfb805-7a42-4f33-80e5-ce040eb648c5",
                    "https://target.scene7.com/is/image/Target/GUEST_2ef57f37-e3e8-4f61-9787-797765d3231b",
                    "https://target.scene7.com/is/image/Target/GUEST_2c42b53c-1822-4863-b2f4-ebb181119f42",
                    "https://target.scene7.com/is/image/Target/GUEST_1462a476-f132-4a97-a548-4520c4cbfa19",
                    "https://target.scene7.com/is/image/Target/GUEST_03436089-9a6e-4158-99e7-ba40c9dde6f5",
                    "https://target.scene7.com/is/image/Target/GUEST_4709df84-04d6-4d4d-9d43-6b0a256d2cf0",
                    "https://target.scene7.com/is/image/Target/GUEST_a3fa97d4-0e4e-4634-9aa0-72ac029a6a87",
                    "https://target.scene7.com/is/image/Target/GUEST_566a0bb6-5367-43d6-9036-55a895288754",
                    "https://target.scene7.com/is/image/Target/GUEST_2b36c860-b4a8-456b-b028-83d937b77533"
                ],
                "product_data": {
                    "brand": "Arcade1Up",
                    "category": null,
                    "color_hex": null,
                    "color_name": null,
                    "description": "WiFi Leaderboards to challenge the world!; Sleek Cabinet Design; Light-up Marquee; Molded Coin Door for Authentic Arcade Look; Dual Speakers for crisp arcade sound; BOE 17 Inch Color Monitor; Real feel Joysticks and Buttons; Over 5 feet tall; 14 Classic Games:  PAC -MAN, DIG DUG, DIG DUG II, PAC-MANIA, PAC & PAL, SUPER PAC-MAN, PAC-MAN PLUS, PAC-LAND GALAGA, GALAXIAN, GALAGA'88, MAPPY, ROMPERS, ROLLING THUNDER",
                    "discounted_price": null,
                    "id": 87,
                    "price": 499.99,
                    "product_currency": "usd",
                    "rating": 4.5,
                    "short_description": null,
                    "size": null,
                    "source": "circle",
                    "source_url": "https://www.target.com/p/pac-man-deluxe-arcade-game/-/A-89474960",
                    "title": "Pac-Man Deluxe Arcade Game"
                },
                "reviews": [],
                "score": 9.0
            },
            {
                "medias": [
                    "https://target.scene7.com/is/image/Target/GUEST_60650dc1-751b-400f-8df3-4122b014dd9c",
                    "https://target.scene7.com/is/image/Target/GUEST_4594b628-8c77-46a4-835b-8d46f62c0b92",
                    "https://target.scene7.com/is/image/Target/GUEST_28273fcd-3cd0-420f-90f9-8d1e2fef3d36",
                    "https://target.scene7.com/is/image/Target/GUEST_838b7c6b-f814-48e0-8d50-0016653ddb0f",
                    "https://target.scene7.com/is/image/Target/GUEST_4a32f74e-aead-4bb3-b38c-fb1ecb6ed5eb",
                    "https://target.scene7.com/is/image/Target/GUEST_9e67c044-0fa8-4914-adeb-88ddc5610740",
                    "https://target.scene7.com/is/image/Target/GUEST_13576eda-5684-414c-9eb4-9fa8399e649b"
                ],
                "product_data": {
                    "brand": "Namco",
                    "category": null,
                    "color_hex": null,
                    "color_name": null,
                    "description": "Eye-popping 3D graphics & funky visuals with a future-ready version of the game; Latest port features scanline, sound settings & the option to switch between versions; Defeat opponents, engulf dots and have oodles of fun playing this family-favorite classic; Eye-popping graphics",
                    "discounted_price": null,
                    "id": 88,
                    "price": 19.99,
                    "product_currency": "usd",
                    "rating": 4.8,
                    "short_description": null,
                    "size": null,
                    "source": "circle",
                    "source_url": "https://www.target.com/p/pac-man-championship-edition-2-xbox-one/-/A-51750807",
                    "title": "PAC-MAN Championship Edition 2 Xbox One"
                },
                "reviews": [],
                "score": 8.0
            },
            {
                "medias": [
                    "https://target.scene7.com/is/image/Target/GUEST_536a9f67-077e-460f-b406-6155a7283a18",
                    "https://target.scene7.com/is/image/Target/GUEST_cc528186-146a-43d0-8abf-3f4ad87f1b34",
                    "https://target.scene7.com/is/image/Target/GUEST_19cefc03-f639-4ef7-b363-3c8f87687a14",
                    "https://target.scene7.com/is/image/Target/GUEST_fab4f6ed-a57a-417e-924c-ec329d85b527"
                ],
                "product_data": {
                    "brand": "Namco",
                    "category": null,
                    "color_hex": null,
                    "color_name": null,
                    "description": "Pac Man World 2 - A unique 3-D-twist on the time-honored game with a storybook-style romp through forests and fields packed with quests, mini games and the deadly ghosts that still haunt Pac-Man.; Pac Man World 3 - Puts Pac-Man into a strange world, leading him to the mysterious Spectral Realm.; Pac Man World Rally - Fast Kart Racing Game - Race against the computer or with your friends. Feel unprecedented sense of speed as you race on some of the most exciting tracks ever created.",
                    "discounted_price": null,
                    "id": 89,
                    "price": 89.99,
                    "product_currency": "usd",
                    "rating": null,
                    "short_description": null,
                    "size": null,
                    "source": "circle",
                    "source_url": "https://www.target.com/p/pac-man-power-pack-playstation-2/-/A-81944539",
                    "title": "Pac-Man Power Pack - PlayStation 2"
                },
                "reviews": [],
                "score": 8.0
            },
            {
                "medias": [
                    "https://target.scene7.com/is/image/Target/GUEST_83e826f3-9b65-41b2-9850-eda7f349be4e",
                    "https://target.scene7.com/is/image/Target/GUEST_321a1aaf-29a1-4dd6-92a5-c86f1bcb20fd",
                    "https://target.scene7.com/is/image/Target/GUEST_bf8289e3-8f1c-44d3-97d1-5d9d042247c7",
                    "https://target.scene7.com/is/image/Target/GUEST_93db131d-c2f6-49ca-ac1e-85eb01f2c82b",
                    "https://target.scene7.com/is/image/Target/GUEST_6d2b0d03-0e4d-47a0-aaa5-3eb10dfb5fbc",
                    "https://target.scene7.com/is/image/Target/GUEST_743f31cb-45f1-4be6-adca-c05562ce2605",
                    "https://target.scene7.com/is/image/Target/GUEST_82a3fda4-74c1-48b4-82f5-72cb901cc4be"
                ],
                "product_data": {
                    "brand": "Arcade1Up",
                    "category": null,
                    "color_hex": null,
                    "color_name": null,
                    "description": "Features: 5 Classic Games; Ms. Pac-Man\u2122; Super Pac-Man\u2122; Galaxian\u2122; King & Balloon\u2122; Rompers\u2122",
                    "discounted_price": null,
                    "id": 90,
                    "price": 149.99,
                    "product_currency": "usd",
                    "rating": 4.5,
                    "short_description": null,
                    "size": null,
                    "source": "circle",
                    "source_url": "https://www.target.com/p/ms-pac-man-countercade/-/A-89402729",
                    "title": "Ms. Pac-Man Countercade"
                },
                "reviews": [],
                "score": 8.0
            },
            {
                "medias": [
                    "https://target.scene7.com/is/image/Target/GUEST_48865c25-db0b-4933-907c-9375b05f3f28",
                    "https://target.scene7.com/is/image/Target/GUEST_0b1c6518-2f6e-460c-b61a-112737ccfb1a",
                    "https://target.scene7.com/is/image/Target/GUEST_6b589563-7747-419f-8b1b-fba7a68ec573",
                    "https://target.scene7.com/is/image/Target/GUEST_039d83f1-dfb1-4e8a-a1a1-047b5c6d6147",
                    "https://target.scene7.com/is/image/Target/GUEST_b5c7fc38-57c4-49bb-8db4-ea48a3eb9421"
                ],
                "product_data": {
                    "brand": "My Arcade",
                    "category": null,
                    "color_hex": null,
                    "color_name": null,
                    "description": "Officially licensed title; High resolution 2.75-in. full color vertical display; Built-in speaker with volume control; Adjustable screen brightness; 3.5 mm output to connect your headphones; Ergonomic design for comfortable play; Powered by either 4 AA batteries (not included) or by a USB-C\u00ae cable (not included); Includes lanyard and user manual; Title includes Ms. Pac-Man\u00ae",
                    "discounted_price": null,
                    "id": 91,
                    "price": 42.99,
                    "product_currency": "usd",
                    "rating": null,
                    "short_description": null,
                    "size": null,
                    "source": "circle",
                    "source_url": "https://www.target.com/p/my-arcade-pocket-player-pro-ms-pac-man/-/A-90126458",
                    "title": "My Arcade\u00ae Pocket Player Pro (Ms. Pac-Man\u2122)"
                },
                "reviews": [],
                "score": 8.0
            },
            {
                "medias": [
                    "https://target.scene7.com/is/image/Target/GUEST_beaa4af2-f9d9-4ac8-bdbd-c691e369157f",
                    "https://target.scene7.com/is/image/Target/GUEST_811f3e6c-bf05-44c7-a9f5-0448f6c4164d",
                    "https://target.scene7.com/is/image/Target/GUEST_e6fc50cd-c54d-4055-8e33-f1a0b46f9810",
                    "https://target.scene7.com/is/image/Target/GUEST_d3f0fada-c1b1-4e54-a0a5-6ab42a73bd7c"
                ],
                "product_data": {
                    "brand": "My Arcade",
                    "category": null,
                    "color_hex": null,
                    "color_name": null,
                    "description": "Officially licensed Bandai Namco\u00ae title; Full-color 2.75-in. vertical screen; Features artwork inspired by the original Pac-Man\u00ae arcade cabinet; Built-in speaker with volume control; 3.5-mm headphone output to connect your headphones; Brightness adjustment; Powered by either 4 AA batteries (not included) or by a USB-C\u00ae cable (not included); Ideal for any game room, office or display case; Includes user manual; Titles include Pac-Man\u00ae",
                    "discounted_price": null,
                    "id": 92,
                    "price": 44.99,
                    "product_currency": "usd",
                    "rating": null,
                    "short_description": null,
                    "size": null,
                    "source": "circle",
                    "source_url": "https://www.target.com/p/my-arcade-micro-player-pro-pac-man/-/A-90112673",
                    "title": "My Arcade\u00ae Micro Player Pro (Pac-Man\u2122)"
                },
                "reviews": [],
                "score": 8.0
            },
            {
                "medias": [
                    "https://target.scene7.com/is/image/Target/GUEST_dfd6a0b0-80af-43a7-9b3f-a09295ce9f50",
                    "https://target.scene7.com/is/image/Target/GUEST_984ed7af-86ca-4b9d-b090-a76e5667447d",
                    "https://target.scene7.com/is/image/Target/GUEST_fd420b02-343b-46ea-8b79-5a97596ddce9",
                    "https://target.scene7.com/is/image/Target/GUEST_5958c1b9-138b-40b2-a33b-5c32b35215d5",
                    "https://target.scene7.com/is/image/Target/GUEST_994d6acc-dc23-4225-88e4-674624a0c8bf",
                    "https://target.scene7.com/is/image/Target/GUEST_cd897b00-2497-42f4-9418-185b58526c21",
                    "https://target.scene7.com/is/image/Target/GUEST_289ba56a-6efd-448c-a0e9-6db9ee295b39",
                    "https://target.scene7.com/is/image/Target/GUEST_716f2767-258b-486a-a50c-c90a1f0fd390"
                ],
                "product_data": {
                    "brand": "Arcade1Up",
                    "category": null,
                    "color_hex": null,
                    "color_name": null,
                    "description": "Features:; - Wi-Fi Leaderboards; - Custom Riser; - Light-Up Marquee; - Molded Coin door; - Includes 14 classic BANDAI NAMCO Entertainment Inc. games",
                    "discounted_price": null,
                    "id": 93,
                    "price": 399.99,
                    "product_currency": "usd",
                    "rating": 4.6,
                    "short_description": null,
                    "size": null,
                    "source": "circle",
                    "source_url": "https://www.target.com/p/arcade1up-pac-man-customizable-arcade/-/A-89919192",
                    "title": "Arcade1Up Pac-Man Customizable Arcade"
                },
                "reviews": [],
                "score": 8.0
            },
            {
                "medias": [
                    "https://target.scene7.com/is/image/Target/GUEST_38490323-3d03-481a-acfd-97c729af306f",
                    "https://target.scene7.com/is/image/Target/GUEST_3d7964b3-0052-4594-bce4-d921c10ebff9",
                    "https://target.scene7.com/is/image/Target/GUEST_4a2fe341-c074-4a03-a225-68e80449d83a",
                    "https://target.scene7.com/is/image/Target/GUEST_d8e2599b-dd24-4db4-9546-977bcac72365",
                    "https://target.scene7.com/is/image/Target/GUEST_4b30628b-e4ca-4806-aaaf-dd242788a8c0",
                    "https://target.scene7.com/is/image/Target/GUEST_52f3d107-e246-4e17-abcc-8d292c76f846",
                    "https://target.scene7.com/is/image/Target/GUEST_cf3266ce-6cfd-4010-ae87-28e5d4d28278",
                    "https://target.scene7.com/is/image/Target/GUEST_2ed316f4-2ea1-4fa5-8338-eac173f266c7"
                ],
                "product_data": {
                    "brand": "Arcade1Up",
                    "category": null,
                    "color_hex": null,
                    "color_name": null,
                    "description": "\u2022    17\" Color LCD screen; \u2022    Assembled Dimensions: 22.85\u201d D x 19.75\u201d W x 57.80\u201d H; \u2022    Box dimensions 48.0\u201d H x 7.0\u201d D x 24.0\u201d W; \u2022    Weight: 67.76 lbs; \u2022    Adjustable Volume; \u2022    Clear Deck Protector; \u2022    Anti-Tip Over Strap",
                    "discounted_price": null,
                    "id": 94,
                    "price": 384.99,
                    "product_currency": "usd",
                    "rating": 4.6,
                    "short_description": null,
                    "size": null,
                    "source": "circle",
                    "source_url": "https://www.target.com/p/arcade1up-pac-mania-bandai-legacy-home-arcade/-/A-86817075",
                    "title": "Arcade1Up Pac-Mania Bandai Legacy Home Arcade"
                },
                "reviews": [],
                "score": 8.0
            }
        ],
        "status": "COMPLETED"
    }
}