import * as React from 'react';


import {Typography, Box, Button} from '@mui/material/';

import { useSelector, useDispatch } from 'react-redux';
import {
    selectChatSessionId,
    setChatSessionId,
    setHistory,
    selectHistory,
    selectDiscourse,
    selectSearching,
    selectProducts,
    addToDiscourse,
    setSearching,
    setProducts,
    clearData,
    loadDiscourse,
    dataSlice
} from '../redux/dataSlice';
import { recordAnonSearch, selectUser, setUser, logout} from '../redux/identitySlice';

const {RestFunctions} = require('../repositories/Network')
const {getProductData} = require('../samples/GetProducts')
const {getDiscourseData} = require('../samples/GetDiscourse')


function DebugVertical({ searchStatus, messageId, detailItem, publishProducts, resetScroll}) {
    const dispatch = useDispatch()

    const discourse = useSelector(selectDiscourse)
    const searching = useSelector(selectSearching)
    const products = useSelector(selectProducts)
    const history = useSelector(selectHistory)
    const user = useSelector(selectUser)
    const chatSessionId = useSelector(selectChatSessionId)

    const getHistory = async (rId) => {
        if (!rId) rId = chatSessionId
        const response = await RestFunctions.getHistory(rId)
        console.log('getHistoryResponse', response)
        return response
    }

    return (
        <Box>
            <Typography sx={{fontSize: '12px'}}>
                <span>DEBUGGING INFORMATION</span>
                <span>local server {process.env.REACT_APP_LOCAL_SERVER ? 'yes (' + process.env.REACT_APP_LOCAL_SERVER + ')' : 'no'}</span>
                <span>Chat Session Id (RequestId:) {chatSessionId}</span>
                <span>Searching: {searching ? 'true' : 'false'}</span>
                <span>Search Status: {searchStatus}</span>
                <span>MessageId: {messageId}</span>
                <span>Products: {products?.length}</span>
                <span>DetailItem: {detailItem?.id}</span>
                <span>Discourse Length: {discourse?.length}</span>
                <span>User displayName: {user?.displayName ? user?.displayName : 'Anonymous'}</span>
                <span>User provider: {user?.providerId ? user?.providerId : 'Anonymous'}</span>
                <span>Products: {products ? products : 'Shit'}</span>
            </Typography>
            <Button onClick={RestFunctions.gcpOnboard}>Test GCP Onboard</Button>
            <Button onClick={RestFunctions.gcpAuth}>Test GCP Auth</Button>
            <Button onClick={() => RestFunctions.gcpData({ target: 'user_data'})}>Test GCP User Data</Button>
            <Button onClick={() => RestFunctions.gcpData({ target: 'search_products', message_id: 1066 })}>Test GCP Data Search - Message Id</Button>
            <Button onClick={RestFunctions.gcpChat}>Test GCP Chat</Button>
            <Button onClick={() => getHistory()}>getHistory</Button>
            <Button onClick={() => resetScroll()}>reset scroll</Button>
            <Button onClick={() => {
                dispatch(loadDiscourse(getDiscourseData()))
            }}>Load Discourse</Button>
            <Button onClick={() => publishProducts(getProductData())}>Load Products</Button>
        </Box>
        
    )
}

export {DebugVertical}