import { createSlice } from '@reduxjs/toolkit'; 

const getProductsFromSearchId = (searchId, hist) => {
  const p = hist?.searchesProducts.searches.find(f => f.search_id === searchId).products
  const rezults = p?.map(e => {
    return { 
        images: e.medias,
        product_data: e.product_data,
        reviews: e.reviews,
        score: e.score
    }
})

if (!rezults) return

const rankedProducts = rezults.map((e, i) => {
    e.id = e.product_data?.source + '-' + e.product_data?.id;
    if (e.product_data.source === 'circle') e.product_data.source = 'Target'
    return e}).sort( (a, b) =>  b.score - a.score || b.product_data.rating - a.product_data.rating )
  return rankedProducts
}

const getDiscourseFromSearchId = (searchId, history) => {
  const session_id = history.searchesProducts.searches.find(f => f.search_id === searchId).session_id
  const messages = history.sessionMessages.find(e => e.session_id === session_id).messages.sort((a, b) => parseInt(a.seq) - parseInt(b.seq))
  const newdisco = messages.map((e, i) => {
    return {
      actor: i%2===0 ? 'user' : 'ai',
      message: e.message_body,
      timestamp: e.created_at
    }})
  return newdisco
}

export const dataSlice = createSlice({
  name: 'data',
  initialState: {
    isInside: false,
    discourse: [],
    searching: false,
    products: null,
    history: null,
    searches: null,
    chatSessionId: null
  },
  reducers: {
    setChatSessionId: (state, action) => {
      return {
        ...state,
        chatSessionId: action.payload
    }

    },
    setInside: (state, action) => {
      //console.log('dataSlice: setInside reducer:', action.payload)
        return {
            ...state,
            isInside: action.payload ? true : false
        }
    },
    reloadDataPerSearchId: (state, action) => {
      const searchId = action.payload
      const historyCopy = JSON.parse(JSON.stringify(state.history))
      const productsCopy = JSON.parse(JSON.stringify(state.products))
      const iProducts = getProductsFromSearchId(searchId, historyCopy)
      const dis = getDiscourseFromSearchId(searchId, historyCopy)

      return {
        ...state,
        products: iProducts,
        discourse: dis
      }

    },
    setHistory: (state, action) => {
      const iSearches = action.payload?.searchesProducts?.searches.filter(s => s.source === 'circle').map(s => {
        return {
          session_id: s.session_id, search_id: s.search_id, search_summary: s.search_summary, created_at: s.created_at
        }
      }).sort( (a, b) => a.created_at - b.created_at)

        return {
            ...state,
            history: action.payload,
            searches: iSearches
        }
    },
    setSearching: (state, action) => {
        return {
            ...state,
            searching: action.payload
        }
    },
    clearData: (state, action) => {
      return {
        ...state,

        discourse: [],
        searching: false,
        products: null,
        chatSessionId: null
    }

    },
    loadDiscourse: (state, action) => {
      return {
        ...state,
        discourse: action.payload
      }
    },
    addToDiscourse: (state, action) => {
      const cc = [...state.discourse]
      const newDiscourse = action.payload
      newDiscourse.timestamp = new Date()
      cc.push(action.payload)
      return {
          ...state,
          discourse: cc
      }
    },
    setProducts: (state, action) => {
      try {
        const rankedProducts = action.payload.map((e, i) => { 
          e.id = e.product_data?.source + '-' + e.product_data?.id;
          if (e.product_data.source === 'circle') e.product_data.source = 'Target'
          return e}).sort( (a, b) =>  b.score - a.score || b.product_data.rating - a.product_data.rating )
        return {
            ...state,
            products: rankedProducts
        }
      } catch (ex) {
        return { ...state, products: []}

      }
    },

  }
});

export const {
  addToDiscourse,
  setInside,
  setSearching,
  setHistory,
  setProducts,
  clearData,
  loadDiscourse,
  setChatSessionId,
  reloadDataPerSearchId
} = dataSlice.actions;

export const selectIsInside = (state) => state.data.isInside;
export const selectDiscourse = (state) => state.data.discourse;
export const selectSearching = (state) => state.data.searching;
export const selectProducts = (state) => state.data.products;
export const selectHistory = (state) => state.data.history;
export const selectSearches = (state) => state.data.searches;
export const selectChatSessionId = (state) => state.data.chatSessionId;

export default dataSlice.reducer;