import axios from "axios";
import { auth } from "../firebase";

const retrieveToken = async () => {
    try {
        const token = await auth.currentUser.getIdToken(true)  //force refresh
        return token
    } catch (err) {
        console.log('Network: Error: Could not retrieve an auth token for current user');

    }
    return null
}
 
const LOCAL_EMAIL = 'sd@buyerry.com'
const GLOBAL_PROTOCOL = 'http'
const SERVER_IP = process.env.REACT_APP_LOCAL_SERVER
const SERVER_URL = GLOBAL_PROTOCOL + '://' + SERVER_IP
const GCP_SERVER_URL = 'https://us-central1-apt-retina-400218.cloudfunctions.net'


const connections = {
    chat: {
        url: process.env.REACT_APP_LOCAL_SERVER ? SERVER_URL : GCP_SERVER_URL,
        operation: 'post',
        endpoint: process.env.REACT_APP_LOCAL_SERVER ? '/talk_to_buyerry' : '/buyerry-mvp-chat',
    },
    history: {
        url: process.env.REACT_APP_LOCAL_SERVER ? SERVER_URL : GCP_SERVER_URL,
        operation: process.env.REACT_APP_LOCAL_SERVER ? 'put' : 'post',
        endpoint: process.env.REACT_APP_LOCAL_SERVER ? '/buyerry_request_history' : '/buyerry-mvp-chat',
    },
    search: {
        url: process.env.REACT_APP_LOCAL_SERVER ? SERVER_URL : GCP_SERVER_URL,
        operation: 'post',
        endpoint: process.env.REACT_APP_LOCAL_SERVER ? '/find_products' : '/buyerry-mvp-chat',
    },
    products: {
        url: process.env.REACT_APP_LOCAL_SERVER ? SERVER_URL : GCP_SERVER_URL,
        operation: process.env.REACT_APP_LOCAL_SERVER ? 'put' : 'post',
        endpoint: process.env.REACT_APP_LOCAL_SERVER ? '/get_products' : '/buyerry-mvp-chat',
    },


    // these are gonna go by due to integration
    gcpOnboard: {
        url: 'https://us-central1-apt-retina-400218.cloudfunctions.net',
        operation: 'post',
        endpoint: '/buyerry-mvp-onboard'
    },
    gcpAuth: {
        url: 'https://us-central1-apt-retina-400218.cloudfunctions.net',
        operation: 'post',
        endpoint: '/buyerry-mvp-auth'
    },
    gcpData: {
        url: 'https://us-central1-apt-retina-400218.cloudfunctions.net',
        operation: 'post',
        endpoint: '/buyerry-mvp-data'
    },
    gcpChat: {
        url: 'https://us-central1-apt-retina-400218.cloudfunctions.net',
        operation: 'post',
        endpoint: '/buyerry-mvp-chat'
    },
}

const chat = async (message, requestId) => {
    const payload = {
        "message": message,
    };
    if (requestId) payload.request_id = requestId;
    if (!process.env.REACT_APP_LOCAL_SERVER) {  // if we are NOT running locally
        payload.target = 'chat';
    } else {
        payload.email = LOCAL_EMAIL; // as opposed to: auth.currentUser.email
    }
    try {
        const response = await transmit(connections.chat, payload);
        return response?.data ? response?.data : response;
    } catch (error) {
        throw error;
    }
};

const getHistory = async (chatSessionId) => {
    if (!chatSessionId) throw new Error('getHistory requires chatSessionId arg')
    const payload = { "request_id": chatSessionId, }

    if (!process.env.REACT_APP_LOCAL_SERVER) {  // if we are NOT running locally
        payload.target = 'history'
    } else {
        payload.email =  LOCAL_EMAIL // as opposed to:  auth.currentUser.email
    }

    const response = await transmit(connections.history, payload)
    return response?.data ? response?.data : response
}

const search = async (messageId) => {
    const payload = { "message_id": messageId, }

    if (!process.env.REACT_APP_LOCAL_SERVER) {  // if we are NOT running locally
        payload.target = 'search'
    } else {
        payload.email =  LOCAL_EMAIL // as opposed to:  auth.currentUser.email
    }
    const response = await transmit(connections.search, payload)
    return response?.data ? response?.data : response
} 



const getProducts = async (messageId) => {
    const payload = { "message_id": messageId, }

    if (!process.env.REACT_APP_LOCAL_SERVER) {  // if we are NOT running locally
        payload.target = 'products'
    } else {
        payload.email =  LOCAL_EMAIL // as opposed to:  auth.currentUser.email
    }

    const response = await transmit(connections.products, payload)
    return response?.data ? response.data : response
}
 

const gcpOnboard = async (userData) => {
    const payload = {
        "message": "called from the ui!",
        "otherField": 8675309,
        "first": "Bob",
        "log_option": false
    }
    const response = await transmit(connections.gcpOnboard, payload)

    return response
} 
const gcpAuth = async (userData) => {
    const payload = {
        "message": "calling gcp auth",
        "otherField": 8675309,
        "first": "Bob",
        "log_option": false
    }
    const response = await transmit(connections.gcpAuth, payload)
    return response
}


const gcpData = async (data) => {
    const payload = {
        ...data,
        "log_option": false
    }
    const response = await transmit(connections.gcpData, payload)

    return response
} 

const secureChat = async (message, requestId) => {
    const payload = {
        "message": message ? message : 'im looking for a new vacuum cleaner',
    }
    if (requestId) payload.request_id = requestId
    const response = await transmit(connections.gcpChat, payload)

    return response?.data ? response?.data : response
} 

export const RestFunctions = {
    query: chat,
    getHistory: getHistory,
    search: search,
    getProducts: getProducts,
    gcpOnboard: gcpOnboard,
    gcpAuth: gcpAuth,
    gcpData: gcpData,
    gcpChat: secureChat,
}


const transmit = async (connection, payload) => {
    const token = await retrieveToken()
    const url = connection.url + connection.endpoint
    try {
        const response = await axios({
            method: connection.operation,
            url: url,
            data: payload,
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Accept": "application/json"
                }
        })
        return response.data
    } catch (err) {
        console.log('Transmit Error:', err)
        return { error: err?.response?.data ? err?.response?.data : err?.response}

    }
}


