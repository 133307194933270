import { signInWithPopup } from "firebase/auth";
import React from "react";
import { auth, googleProvider } from "../../firebase";
import {ReactComponent as GoogleSigninLogo} from '../../assets/google.svg';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, setUser } from '../../redux/identitySlice';
import { clearData} from '../../redux/dataSlice';
import gaEvent from "../handleEvent";

function SignIn() {

  const user = useSelector(selectUser)
  const dispatch = useDispatch()

  const googleSignup = async () => {
    try {
      const z = await signInWithPopup(auth, googleProvider)
          .then(() => {
            gaEvent('User', 'User Signed In', 'User Signed In')
          })
      dispatch(setUser(z.user))
    } catch (error) {
      console.log(error)
    }
  }

  const gSignup = async () => {
    await googleSignup()
    dispatch(clearData())
  }

  return (
    <>
      { user?.providerId !== 'firebase' &&
        <div className="sign-in-container">
          <span className='signup-header'>Sign Up / Sign in</span><br />
          <div className="svgwrapper" onClick={gSignup}>
            <GoogleSigninLogo />
          </div>
        </div>
      }
    </>
  )
}

export { SignIn }

