import React, { useState } from "react";
import { Stack, Box, Paper } from "@mui/material/";
import {
  selectSearches,
  reloadDataPerSearchId,
  selectChatSessionId,
  setChatSessionId,
} from "../redux/dataSlice";
import { selectAnonSearches, selectUser } from "../redux/identitySlice";
import { useSelector, useDispatch } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";

import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function HistorySelector({ history, leftPanelVisible }) {
  const searches = useSelector(selectSearches);
  // const anonSearches = useSelector(selectAnonSearches);
  const user = useSelector(selectUser);
  const chatSessionId = useSelector(selectChatSessionId);
  const [currentItem, setCurrentItem] = useState(null);
  const dispatch = useDispatch();

  const selectSearchHistory = (searchId, sessionId) => {
    dispatch(reloadDataPerSearchId(searchId));
    dispatch(setChatSessionId(sessionId));
  };

  const handleItemClick = (item) => {
    setCurrentItem(item);
  };

  return (
    <Box>
      <Accordion
        defaultExpanded
        sx={{
          background: "none",
          boxShadow: "none",
          cursor: "pointer",
          textAlign: "left",
          mt: 4,
        }}
      >
        {leftPanelVisible && (
          <Box>
            <Box
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                background: "none",
                boxShadow: 0,
                cursor: "pointer",
                textAlign: "left",
                mt: 5,
                mb: 3,
                display: "flex",
                fontSize: "16px",
              }}
            >
              <Box
                sx={{
                  pl: 3,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textWrap: 'nowrap',
                }}
              >
                Recent Requests
              </Box>
            </Box>
            <Divider sx={{ mt: 2 }} />
          </Box>
        )}
        {!leftPanelVisible && (
          <Box
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              background: "none",
              boxShadow: 0,
              cursor: "pointer",
              textAlign: "center",
              mt: 5,
              mb: 2,
            }}
          >
            <Box sx={{ marginBottom: 2, height: 25 }}>
              <br />
            </Box>
            <Divider sx={{ mt: 2 }} />
          </Box>
        )}

        <AccordionDetails className="productItems">
          <Stack
            spacing={0}
            sx={{
              background: "none",
              boxShadow: 0,
              cursor: "pointer",
              textAlign: "left",
            }}>
            {leftPanelVisible &&
              searches
                ?.filter((e) => {
                  if (!user) return e.session_id === chatSessionId;
                  return e;
                }).filter((item) => item.search_summary).map((item, idx) => (
                  <Item
                    key={idx}
                    onClick={() => {
                      selectSearchHistory(item.search_id, item.session_id);
                      handleItemClick(item);
                    }}
                    sx={{
                      background: "none",
                      boxShadow: 0,
                      cursor: "pointer",
                      textAlign: "left",
                      display: "block",
                      ml: 1,
                      mt: 2,
                      mr: 1,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      borderRadius: 2,
                      fontSize: "16px",
                      backgroundColor: currentItem === item ? "#869da6" : "none",
                      color: currentItem === item ? "white" : "none",
                      "&.MuiPaper-root:hover": {
                        backgroundColor:
                          currentItem === item ? "#869da6" : "#869da699",
                        color: "white",
                        boxShadow: "none",
                        border: "unset",
                      },
                    }}
                  >
                    {item?.search_summary?.charAt(0).toUpperCase() + item?.search_summary?.slice(1)}
                  </Item>
                ))}
            {!leftPanelVisible &&
              searches
                ?.filter((e) => {
                  if (!user) return e.session_id === chatSessionId;
                  return e;
                }).filter((item) => item.search_summary).map((item, idx) => (
                  <Box>
                    <Tooltip
                      key={idx}
                      title={
                        item?.search_summary?.charAt(0).toUpperCase() +
                        item?.search_summary?.slice(1)
                      }
                      sx={{
                        fontSize: "20px",
                        zIndex: "3000000 !important",
                      }}
                    >
                      <div>
                      <Item
                        key={idx}
                        onClick={() => {
                          selectSearchHistory(item.search_id, item.session_id);
                          handleItemClick(item);
                        }}
                        sx={{
                          background: "none",
                          boxShadow: 0,
                          cursor: "pointer",
                          alignContent: "center",
                          textAlign: "center",
                          borderRadius: 100,
                          border: "unset",
                          margin: "0 4px",
                          mt: 2,
                          fontSize: "16px",
                          backgroundColor:
                            currentItem === item ? "#869da6" : "#f0f0f0",
                          color: currentItem === item ? "white" : "none",
                          "&.MuiPaper-root:hover": {
                            backgroundColor:
                              currentItem === item ? "#869da6" : "#869da699",
                            color: "white",
                            boxShadow: "none",
                            border: "unset",
                          },
                        }}
                      >
                        {idx + 1}
                      </Item>
                      </div>
                    </Tooltip>
                  </Box>
                ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export { HistorySelector };
