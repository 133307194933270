import React, { useState, useEffect, useRef, useCallback } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search'; 
import KeyboardVoice from '@mui/icons-material/KeyboardVoice';
import ClearIcon from '@mui/icons-material/Clear';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import gaEvent from "./handleEvent";

function TextFront({converseHandler}) {
    const [query, setQuery] = useState('');

    const querySubmitHandler = (e) => {
        e.preventDefault();
        converseHandler(query); // Trigger conversation handler
        gaEvent('User', 'First Message Sent', 'First Message Sent');
        setQuery(''); // Clear input after submitting
    }
    const queryUpdate = (v) => {
        setQuery(v)
    }
    const queryUpdateHandler = () => {
        console.log('GOTO INSIDE!!!')
    }

    return (
        <Paper  elevation={0} sx={{
            margin: 'auto',
            boxShadow: '0 0 100px 1px #dddddd',
            width: '100%',
            maxWidth: '600px',
            backgroundColor: 'white',
            borderRadius: "20px",
            display: 'inline-block',
        }}
                className="search-string"
                component="form"
                onSubmit={querySubmitHandler}
        >

            {/* <Divider variant='middle' /> */}
            <Box sx={{ display: 'flex'}}>
                <IconButton type="button" sx={{ p: '5px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>

                <InputBase
                    sx={{ ml: 1, flex: 1 , maxWidth: '600px', width: '80%', color: '#5c8d9e'}}
                    placeholder="ask Buyerry, e.g. `I need a laptop` or `find me a coffee machine`"
                    inputProps={{ 'aria-label': 'search for what you need' }}
                    value={query}
                    onChange={(e) => queryUpdate(e.target.value)}
                />

                <IconButton type="button" sx={{ p: '5px' }} aria-label="search" onClick={querySubmitHandler}>
                    <ArrowForwardIosOutlinedIcon />
                </IconButton>

            </Box>
        </Paper>
    )
}

export {TextFront}