import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import logo from './assets/buyerry.svg';
import './App.css';
import { SignIn } from './components/auth/SignIn'
import { AuthDetails } from './components/auth/AuthDetails'
import { useSelector, useDispatch } from 'react-redux';
import { selectAnonSearches, selectUser} from './redux/identitySlice';
import { Box, Backdrop, CircularProgress} from '@mui/material/';
import { setInside, selectChatSessionId, selectIsInside, addToDiscourse, clearData, setChatSessionId } from './redux/dataSlice';
import { MainPage } from './pages/MainPage';
import { TextFront } from './components/TextFront';
import { SignInDialog } from './components/SignInDialog';
import { signOut } from "firebase/auth";
import { auth } from "./firebase";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from './pages/useMediaQuery';
import gaEvent from "./components/handleEvent"
const {RestFunctions} = require('./repositories/Network')

const theme = createTheme({
  typography: {
    fontFamily: [
        ['SF Pro Display', 'sans-serif'],
        'Roboto',
        'Raleway',
        'Open Sans',
    ].join(','),
    h1: {
        fontSize: '5rem',
        fontFamily: 'Raleway',
    },
    h2: {
        fontSize: '3.5rem',
        fontFamily: 'Open Sans',
        fontStyle: 'bold',
    },
    h3: {
        fontSize: '2.5rem',
        fontFamily: 'Roboto',
    },
},
});

function App() {
  ReactGA.initialize('G-42HB4PSV1E');

  const user = useSelector(selectUser)
  const isInside = useSelector(selectIsInside)
  const isMobile = useMediaQuery('(max-width: 768px)')
  const chatSessionId = useSelector(selectChatSessionId)
  const anonSearches = useSelector(selectAnonSearches)
  const [querying, setQuerying] = useState(false);
  const dispatch = useDispatch()
  const [isTyping, setIsTyping] = useState(false);

  const converse = async (message) => {
    if (checkSignupRequired()) return
    if (message === '') return

    setQuerying(true);
    setIsTyping(true);
    dispatch(addToDiscourse({ actor: 'user', message: message}))

    try {
        const response = await RestFunctions.query(message, chatSessionId); // Pass setIsTyping to query
        if (response) {
            if (response.request_id) {
                dispatch(setChatSessionId(response.request_id));
            }
            dispatch(addToDiscourse({ actor: 'ai', message: response.ai_response }));
            dispatch(setInside(true));
        }
    } finally {
        setIsTyping(false);
    }
  }

  const checkSignupRequired = () => {
    if (!user && anonSearches?.length > 1) {
      setOpenDialog(true);
      gaEvent('User', 'Signup Requested', 'Signup Requested');
      return true
    }
    return false
  }

  const userSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("AuthDetails: sign out successful");
        gaEvent('User', 'Sign Out', 'User Signed Out');
        dispatch(clearData(true))
      })
      .catch((error) => console.log(error));
  };

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpenDialog = () => {
      setOpenDialog(true);
  }
  const handleCloseDialog = () => {
      setOpenDialog(false);
  }

  if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) {
    var viewportmeta = document.querySelectorAll('meta[name="viewport"]')[0];
    if (viewportmeta) {
        viewportmeta.content = 'width=device-width, minimum-scale=1.0, maximum-scale=1.0';
        document.body.addEventListener('gesturestart', function() {
            viewportmeta.content = 'width=device-width, minimum-scale=0.25, maximum-scale=1.6';
        }, false);
    }
}

  return (

      <div className="App" style={{
          height: '100vh',
          width: '100vw',
          color: 'black',
          backgroundColor: `${isInside ? 'white' : '#f7f7f7'}`,
          fontSize: 16,
          fontFamily: 'SF Pro Display',
      }}>
          <ThemeProvider theme={theme}>
              {!user &&
                  <SignInDialog
                      message='You have been granted 2 anonymous searches. If you wish to continue your journey, signup with our service for free'
                      openDialog={openDialog}
                      handleCloseDialog={handleCloseDialog}
                      className="sign-in-bg"
                  />
              }

              <AuthDetails show={false}/>
              {user?.accessToken || isInside ?
                  <>
                      <MainPage
                          chatSessionId={chatSessionId}
                          conversationHandler={converse}
                          checkSignupRequired={checkSignupRequired}
                          userSignOut={userSignOut}
                          isMobile={isMobile}
                          isTyping={isTyping}
                          setIsTyping={setIsTyping}
                      />
                  </>
                  :
                  <>
                      <Backdrop
                          sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000000}}
                          open={querying}
                          className='main-backdrop'>
                          <CircularProgress color="inherit"/>
                      </Backdrop>
                      <Box
                          sx={{
                              height: '100vh',
                              width: '100vw',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'top',
                              textAlign: 'center',
                              overflow: 'hidden',
                          }}>

                          <Box
                              sx={{
                                  maxWidth: '100%',
                                  width: '100%',
                                  padding: '30px',
                                  alignContent: 'center',
                              }}
                          >
                              <header className="App-header">
                                  <img src={logo} className="App-logo" alt="logo"/>
                              </header>
                              <Box sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '100%',
                              }}>
                                  <Box sx={{
                                      width: '100%',
                                      mt: 4,
                                  }}>
                                      <TextFront converseHandler={converse}/>
                                  </Box>
                              </Box>
                              <SignIn/>
                          </Box>
                      </Box>
                      <div className='footers'>
                          <span><a href='https://buyerry.com/about/'>About</a></span>
                          <span><a href='https://buyerry.com/investors/'>Investment</a></span>
                          <span><a href='https://buyerry.com/privacy-policy/'>Privacy Policy</a></span>
                          <span><a href='https://buyerry.com/terms-and-conditions/'>Terms and Conditions</a></span>
                      </div>
                  </>
              }
          </ThemeProvider>


      </div>
  );
}

export default App;
