import React, {useState, useRef, useEffect} from 'react';
import { ImageList, ImageListItem } from '@mui/material/';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {min} from "moment";

function DetailImageCarousel({ detailItem, handlePickPhoto }) {
    const [currentItem, setCurrentItem] = useState(0);
    const [fullscreenImage, setFullscreenImage] = useState(null);
    const sliderRef = useRef(null);
    const lensRef = useRef([]);
    const magnifierImageRef = useRef([]);
    const startTouchRef = useRef({ x: 0, y: 0 });
    const startBgPosRef = useRef({ x: 0, y: 0 });

    const handleItemClick = (idx) => {
        setCurrentItem(idx);
        sliderRef.current.slickGoTo(idx);
    };

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
        beforeChange: (current, next) => setCurrentItem(next),
    };

    const handleImageClick = (src) => {
        if (window.innerWidth <= 768) {
            setFullscreenImage(src);
        }
    };

    const closeFullscreen = () => {
        setFullscreenImage(null);
    };

    const handleMouseMove = (e, idx) => {
        const { clientX, clientY, currentTarget } = e;
        const rect = currentTarget.getBoundingClientRect();
        const x = clientX - rect.left;
        const y = clientY - rect.top;
        const lens = lensRef.current[idx];
        const zoomImg = magnifierImageRef.current[idx];
        const zoomLevel = 2; // Adjust as needed

        console.log("x=",x,"y=",y);

        // console.log("zoomImg.style.backgroundPosition", zoomImg.style.backgroundPosition)
        if (lens && zoomImg) {
            zoomImg.style.display = 'block';

            const containerWidth = rect.width;
            const containerHeight = rect.height;
            const ratioX = x / containerWidth;
            const ratioY = y / containerHeight;

            console.log("ratioX=",ratioX,"ratioY=",ratioY,"containerWidth=",containerWidth);

            lens.style.left = `${x}px`;
            lens.style.top = `${y}px`;

            // Update background position of the zoom image
            const zoomX = ratioX * (containerWidth * zoomLevel - containerWidth);
            const zoomY = ratioY * (containerHeight * zoomLevel - containerHeight);

            zoomImg.style.backgroundPosition = `-${zoomX}px -${zoomY}px`;

            console.log("zoomImg.style.backgroundPosition",zoomImg.style.backgroundPosition)
        }
    };
    const handleMouseLeave = (idx) => {
        if (lensRef.current[idx]) lensRef.current[idx].style.display = 'none';
        if (magnifierImageRef.current[idx]) magnifierImageRef.current[idx].style.display = 'none';
    };
    const handleTouchStart = (e) => {
        if (!fullscreenImage) return;

        console.log("touch started");

        const touch = e.touches[0];
        const rect = e.currentTarget.getBoundingClientRect();
        startTouchRef.current = { x: touch.clientX - rect.left, y: touch.clientY - rect.top };
        const zoomImg = document.querySelector('.fullscreen-img');
        const [bgPosX, bgPosY] = zoomImg.style.backgroundPosition.split(' ').map(pos => parseFloat(pos));
        startBgPosRef.current = { x: bgPosX, y: bgPosY };
    };
    const handleTouchMove = (e) => {
        if (!fullscreenImage) return;

        const touch = e.touches[0];
        const rect = e.currentTarget.getBoundingClientRect();
        const x = touch.clientX;
        const y = touch.clientY;
        const zoomImg = document.querySelector('.fullscreen-img');
        const zoomLevel = 2;

        const ratioX = x / rect.width;
        const ratioY = y / rect.height;
        const zoomX = Math.min(rect.width * zoomLevel, ratioX * (rect.width * zoomLevel - rect.width));
        const zoomY = Math.min(rect.height * zoomLevel, ratioY * (rect.height * zoomLevel - rect.height));
        console.log("rect", rect);
        console.log("x", x);
        console.log("y", y);
        console.log("zoomImg.style.backgroundPosition", zoomImg.style.left);
        console.log("zoomImg.style.backgroundPosition", zoomImg.style.left);

        zoomImg.style.backgroundPosition = `-${zoomX}px -${zoomY}px`;
        console.log("zoomImg.style.backgroundPosition", zoomImg.style.left);
    };
    const handleTouchEnd = (idx) => {
        if (lensRef.current[idx]) lensRef.current[idx].style.display = 'none';
        if (magnifierImageRef.current[idx]) magnifierImageRef.current[idx].style.display = 'none';
    };

    return (
        <>
            {fullscreenImage && (
                <div className="fullscreen-overlay"
                >
                    <img
                        src={fullscreenImage}
                        alt="Fullscreen"
                        className="fullscreen-img"
                        onClick={closeFullscreen}
                        // onTouchStart={handleTouchStart}
                        // onTouchMove={handleTouchMove}
                        // onTouchEnd={handleTouchEnd}
                        style={{
                               backgroundImage: `url(${fullscreenImage})`,
                               backgroundSize: 'cover',
                               backgroundRepeat: 'no-repeat',
                               width: '100%',
                               height: '100%',
                        }}
                    />
                </div>
            )}

            <Slider ref={sliderRef} {...settings}>
                {detailItem?.images.map((item, idx) => (
                    <div key={idx}
                         style={{padding: '0 5px', position: 'relative'}}
                         className="zoom-container magnifier-container"
                         // onMouseMove={(e) => handleMouseMove(e, idx)}
                         // onMouseLeave={() => handleMouseLeave(idx)}
                    >
                        <img
                            style={{
                                width: '100%',
                                height: '50vh',
                                objectFit: 'cover',
                            }}
                            src={item}
                            alt={detailItem?.product_data?.title}
                            onClick={() => handleImageClick(item)}
                        />
                        <div
                            ref={(el) => lensRef.current[idx] = el}
                            className="magnifier-lens"
                        ></div>
                        <div
                            ref={(el) => magnifierImageRef.current[idx] = el}
                            className="magnifier-image"
                            style={{
                                backgroundImage: `url(${item})`,
                            }}
                        ></div>
                    </div>
                ))}
            </Slider>
            <ImageList
                sx={{px: '0px', display: 'flex'}}
                cols={detailItem?.images?.length}
                rowHeight={50}
                style={{
                    gridTemplateColumns: 'repeat(7, 1fr) !important',
                    gap: '4px',
                }}
            >
                {detailItem?.images.map((item, idx) => (
                    <ImageListItem key={idx} sx={{paddingRight: '3px', mb: '10px'}}>
                    <img
                            style={{
                                width: '50px',
                                maxHeight: '50px',
                                mb: 1,
                                border: currentItem === idx ? '4px solid #869da6' : 'none',
                            }}
                            onClick={() => {
                                handlePickPhoto(item);
                                handleItemClick(idx);
                            }}
                            src={item}
                            alt={detailItem?.product_data?.title}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </>
    );
}

export default DetailImageCarousel;
export { DetailImageCarousel };
