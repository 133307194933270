import React, { useState, useEffect, useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {Button, Typography, Rating} from '@mui/material/';
import { DetailImageCarousel } from './DetailImageCarousel'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import useClickOutside from '../pages/useClickOutside';
import {decode} from "html-entities";
import gaEvent from "./handleEvent";

function DetailViewMob({product, onClose}) {

    // console.log({product});
    const [detailPhoto, setDetailPhoto] = useState(null);
    const panelRef= useRef(null);

    useClickOutside(panelRef, onClose);

    useEffect(() => {
        if (!product?.images) return
        setDetailPhoto(product.images[0]);
    }, [product])

    const handlePickPhoto = (item) => {
        setDetailPhoto(item)
    }

    return (
        <>
        { product &&
        <Stack spacing={1}
               className='detailed-stack'
               sx={{
                   height: '100%',
                   borderRadius: '0',
                   p: 3,
                   pb: 5,
                   mb: 5,
                   display: 'flex',
                   overflowY: 'scroll',
                   overflowX: 'hidden',
                   alignItems: 'flex-start',
               }}
               ref={panelRef}
        >

            <Box className='detailed-photo' sx={{width: '100%', pt: 1, pb: 4, mr: 2, flex: '1 2', minWidth: '350px'}}>
                <Box>
                    <DetailImageCarousel detailItem={product} handlePickPhoto={handlePickPhoto} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 0, m: 0, borderRadius: "5px"}}>
                        <Button
                            component="a"
                            variant='contained'
                            sx={{
                                height: '40px',
                                width: '120px',
                                wordWrap: 'break-word',
                                fontWeight: 500,
                                color: '#ffffff',
                                fontSize: '16px',
                                mt: 2,
                                borderRadius: 2,
                                boxShadow: 0,
                                backgroundColor: '#0096cd',
                                '&:hover': {
                                    bgcolor: '#048abb',
                                    boxShadow: 'none',
                                }
                            }}
                            href={product?.product_data?.source_url}
                            target='_blank'
                            onClick={() => {
                                    gaEvent('User', 'Product Purchase', 'Buy Button Clicked');
                                }
                            }
                        >
                            Buy
                        </Button>

                        <Box sx={{ display: 'flex'}}>
                            <FavoriteBorderIcon sx = {{ fontSize: 24, color: '#0096cd', pt: 3, mb: 2}} />
                        </Box>
                    </Box>
                </Box>
            </Box>


            <Box sx={{width: '100%', textAlign: 'left', p: 1, pl: 0, mt: 4, ml: '0 !important'}}>
                <Typography sx={{fontWeight: '800', color: '#454545', fontSize: '16px'}}>
                    {product?.product_data?.brand} @ {product?.product_data?.source === 'walmart' ? 'Walmart' : product?.product_data?.source}
                </Typography>
                <Typography sx={{fontWeight: '800', color: '#454545', fontSize: '30px', lineHeight: '1.2'}}>
                    {decode(product?.product_data?.title)}
                </Typography>
                <Box sx={{pt: 1, height: '40px'}}>
                    {/*<StarIcon sx={{fontSize: 18, color: `${product?.product_data?.rating ? 'orange' : '#ADADAD'}`, mr: 1, mt: '4px', lineHeight: 1.1}}/>*/}
                    {
                        <Box sx={{display: 'flex', ml: '-3px'}}>
                            <Rating sx={{paddingTop: '0px'}} precision={0.1} value={product?.product_data?.rating}/>
                            <Typography sx={{
                                fontWeight: '800',
                                color: '#737373',
                                fontSize: '16px',
                                pt: '1px',
                                pl: '4px',
                            }}> {product?.product_data?.rating}</Typography>
                        </Box>
                    }
                </Box>
                <Box sx={{pt: 1, height: '50px'}}>
                    {product?.product_data?.discounted_price && product?.product_data?.discounted_price !== product?.product_data?.price &&
                        <Typography sx={{
                            fontSize: '14px',
                            width: '250px',
                            fontWeight: '800',
                            color: '#000000',
                            textDecoration: 'line-through'
                        }}>${product?.product_data?.price}</Typography>
                    }
                    {(!product?.product_data?.discounted_price || product?.product_data?.discounted_price == product?.product_data?.price) &&
                        <Typography sx={{
                            fontWeight: '800',
                            color: '#0096cd',
                            fontSize: '22px'
                        }}>${product?.product_data?.price}</Typography>
                    }
                    {product?.product_data?.discounted_price && product?.product_data?.discounted_price !== product?.product_data?.price &&
                        <Typography sx={{
                            fontSize: '18px',
                            width: '250px',
                            fontWeight: '800',
                            color: '#0096cd',
                            mb: 1
                        }}>${product?.product_data?.discounted_price}</Typography>
                    }
                </Box>
                <br/>
                <Typography
                    variant='caption'
                    sx={{
                        fontWeight: '800',
                        color: '#666666',
                        fontSize: '18px'
                    }}>
                    Description:
                </Typography>
                <Box elevation={0}
                     sx={{flexGrow: 1, overflow: 'visible', height: '220px', marginTop: '0px', lineHeight: '1.4', mt: 1, mb: 2}}>
                    <Typography
                        variant='caption'
                        sx={{
                            fontWeight: '400',
                            color: '#555555',
                            fontSize: '16px'
                        }}>
                        {decode(product?.product_data?.description)}
                    </Typography>
                    <Box sx={{width: '100%', height: '200px'}}></Box>
                </Box>
            </Box>
        </Stack>
        }
        </>
    )
}

export {DetailViewMob}