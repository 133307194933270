import * as React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { Avatar } from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import logo from "../assets/buyerry.svg";
import PersonIcon from "@mui/icons-material/Person";
import moment from "moment";
import {Button} from "@mui/material/";
import {hover} from "@testing-library/user-event/dist/hover";

function ChatDialog({ chat, index, startSearchHandler, searching, showSearch }) {
    let fc = "#5c8898";
    let ml = "5px";
    let pp = "";
    if (chat.actor === "ai") {
        fc = "#0096cd";
        ml = "0px";
        pp = "Buyerry";
    }

    if (pp == "Buyerry") {
        return (
            <Paper
                elevation={0}
                key={index}
                sx={{
                    display: "block",
                    textAlign: "left",
                    ml: `${ml}`,
                    p: 0,
                    mx: 3,
                    color: `${fc}`,
                    marginRight: "10%",
                    marginBottom: "30px",
                    backgroundColor: 'transparent',
                }}
                style={{
                    textAlign: '-webkit-left'
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "left",
                    }}
                >
                    <img
                        src={logo}
                        className="App-logo-min"
                        alt="logo"
                        style={{ height: "24px" }}
                        sx={{ height: "30px", pointerEvents: "none" }}
                    />
                    &nbsp;&nbsp;&nbsp;
                </Box>
                <Box
                    sx={{
                        m: 1,
                        p: 1,
                        pl: 2,
                        pr: 2,
                        borderRadius: "0 10px 10px 10px",
                        backgroundColor: "#f7f7f7",
                        color: "#5c8898",
                        marginLeft: "0px !important",
                        maxWidth: '450px',
                        textAlign: 'left',
                        fontSize: '16px',
                        lineHeight: '1.5'
                    }}
                >
                    {chat.message}
                    <div
                        style={{
                            paddingTop: "6px",
                            fontSize: "10px",
                            opacity: 0.6,
                            display: 'block',
                        }}
                    >
                        {moment(chat.timestamp).format("hh:mm A ") +
                            Intl.DateTimeFormat().resolvedOptions().timeZone}
                    </div>
                    { searching &&
                        <>
                        <Button
                            sx={{
                                color: 'silver',
                                backgroundColor: '#f7f7f7',
                                mt: 1,
                                mb: 1,
                                pl: 2,
                                pr: 2,
                                '&:hover': {
                                    backgroundColor: '#f7f7f7',
                                    boxShadow: 'none',
                                },
                                cursor: 'not-allowed'
                            }}
                        >
                            Searching...
                        </Button>
                        </>
                    }
                    { !searching &&
                        <>
                        <Button onClick={startSearchHandler}
                            type="button"
                            aria-label="search"
                            sx={{
                                color: 'white',
                                backgroundColor: '#0096cd',
                                mt: 1,
                                mb: 1,
                                pl: 2,
                                pr: 2,
                                '&:hover': {
                                    backgroundColor: '#048abb',
                                    color: 'white',
                                },
                            }}
                        >
                            Find Products
                        </Button>
                        </>
                    }
                </Box>
            </Paper>
        );
    } else {
        return (
            <Paper
                elevation={0}
                key={index}
                sx={{
                    display: "block",
                    textAlign: "right",
                    ml: `${ml}`,
                    p: 0,
                    mx: 3,
                    color: `${fc}`,
                    marginLeft: "10%",
                    marginBottom: "30px",
                    backgroundColor: 'transparent',
                }}
                style={{
                    textAlign: '-webkit-right'
                }}
            >
                <Box sx={{ display: "flex", flexDirection: 'row-reverse', textAlign: 'right' }}>
                    <Avatar
                        alt="Remy Sharp"
                        style={{ height: "24px", width: "24px" }}
                        src="/static/images/avatar/1.jpg"
                    >
                        <PersonIcon />
                    </Avatar>
                    <Typography sx={{ fontWeight: "bold", pr: 1 }}>
                        You
                    </Typography>

                </Box>
                <Box
                    sx={{
                        m: 1,
                        p: 1,
                        pl: 2,
                        textAlign: `${pp === "Buyerry" ? "left" : "right"}`,
                        borderRadius: "10px 0 10px 10px",
                        marginLeft: "0px !important",
                        color: "white",
                        paddingLeft: '12px',
                        marginRight: '0px !important',
                        maxWidth: '450px',
                        backgroundColor: '#869da6',
                        fontSize: '16px',
                        lineHeight: '1.5'
                    }}
                >
                    {chat.message}
                    <div
                            style={{
                                paddingTop: "6px",
                                fontSize: "10px",
                                textAlign: 'right',
                                opacity: 0.6,
                                display: 'block'
                            }}
                        >
                            {moment(chat.timestamp).format("hh:mm A ") +
                                Intl.DateTimeFormat().resolvedOptions().timeZone}
                    </div>
                </Box>
            </Paper>
        );
    }
}

export { ChatDialog };
