import ReactGA from 'react-ga4';

const gaEvent = (category, action, label = '', value = 0) => {
  ReactGA.event({
    category,
    action,
    label,
    value
  });
};

export default gaEvent;